<template>
  <div class="main" id="main">
    <div class="main-center">
      <div class="top">
        <img class="logo" src="@/assets/logo.png">
        <!-- 导航 -->
        <ul class="top-menu">
          <!-- <li index="1"><a href="#" @click="goIndex">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首页</a></li>
          <li index="2"><a href="#" @click="goNews">新闻资讯</a></li>
          <li index="3"><a href="#" @click="goContest">赛事动态</a></li>
          <li index="4"><a href="#">精彩视频</a></li>
          <li index="5"><a href="#">格斗讲堂</a></li>
          <li index="6"><a href="#">通知公告</a></li>
          <li index="7"><a href="#" style="color:yellow;">认证查询</a></li>
          <li index="8"><a href="#">&nbsp;</a></li>
          <li index="9"><a href="#">&nbsp;</a></li>
          <li index="10" class="top-menu-right"><img src="@/assets/top-menu-right.png"></li> -->
          <li v-for="nav in navList" :key="nav.id"  :class="{ liActive: nav.slug == '' }" @click="go(nav.slug)">
              {{ nav.name }}
          </li>
        </ul>

        <!-- 用户图标 -->
        <div class="user">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
        </div>
      </div>
      
      <!-- 大图走马灯 -->
      <el-carousel class="carousel" :interval="3000" height="768px" indicator-position="outside" >
        <el-carousel-item v-for="item in imgList.data" :key="item.id" >
          <div style="width：470px">
            <img style="width:100%; heigth:100%; object-fit:cover" :src="item.cover" >
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- 通知公告 -->
      <div class="notice">
        <table  width="100%"  style="background-color: #181818; margin-bottom:12px;">
          <tr>
            <td width="20px"><div class="left-red"></div></td>
            <td><div class="tips-title">
              <h3>通知公告</h3>
              <span>NOTICE</span>
            </div></td>
            <td><div class="more" @click="noticeMore">更多...</div></td>
          </tr>
        </table>
        <el-row :gutter="12">
          <el-col :span="8" v-for="item in noticeList" :key="item.id">
            <el-card shadow="hover" style="background-color:#701906; border-color:#701906;color:white; min-height: 138px;max-height: 138px;margin-bottom: 5px;">
              <!-- <img class="notice-new" src="@/assets/new.png"> -->
              <p class="notice-title" @click="goDetail(item.id)">{{ item.title }}</p>
              <p class="notice-date">{{item.updateTime}}</p>
              <p style="cursor: pointer;" @click="goDetail(item.id)">{{item.subtitle}}</p>
            </el-card>
          </el-col>
          <!-- <el-col :span="8">
            <el-card shadow="hover" style="background-color:#701906; border-color:#701906;color:white;">
              <img class="notice-new" src="@/assets/new.png">
              <p class="notice-title">裁判员教练员培训通知</p>
              <p class="notice-date">24/04/10 10:00</p>
              <p>尊敬的各位裁判员、教练员：为了提高我国裁判员、教练员的业务能力</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" style="background-color:#701906; border-color:#701906;color:white;">
              <p class="notice-title">裁判员教练员培训通知</p>
              <p class="notice-date">24/04/10 10:00</p>
              <p>尊敬的各位裁判员、教练员：为了提高我国裁判员、教练员的业务能力</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" style="background-color:#701906; border-color:#701906;color:white;">
              <p class="notice-title">裁判员教练员培训通知</p>
              <p class="notice-date">24/04/10 10:00</p>
              <p>尊敬的各位裁判员、教练员：为了提高我国裁判员、教练员的业务能力</p>
            </el-card>
          </el-col> -->
        </el-row>
      </div>

      <!-- 主要直播 -->
      <div class="m-live">
        <el-row :gutter="12">
          <el-col :span="8" v-for="item in mLiveList" :key="item.id">
            <el-card shadow="hover" style="background-color:#181818; border-color:#181818;color:white;" >
              <img :src="item.cover" class="live-1-image" @click="goDetail(item.id)">
              <div class="m-live-font">
                <span @click="goDetail(item.id)">{{item.title}}</span>
                <div class="bottom clearfix" @click="goDetail(item.id)">
                   <el-button type="text" style="color:#ADA8A8;font-family: 阿里妈妈数黑体;font-size: 18px;font-weight:bold;">正在直播</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
          <!-- <el-col :span="8">
            <el-card shadow="hover" style="background-color:#181818; border-color:#181818;color:white;">
              <img src="@/assets/live-m-2.png" class="live-1-image">
              <div class="m-live-font">
                <span>Watch MMA Live Stream | DAZN DE</span>
                <div class="bottom clearfix">
                  <el-button type="text" style="color:#ADA8A8;font-family: 阿里妈妈数黑体;font-size: 18px;font-weight:bold;">正在直播</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" style="background-color:#181818; border-color:#181818;color:white;">
              <img src="@/assets/live-m-3.png" class="live-1-image">
              <div class="m-live-font">
                <span>Watch MMA Live Stream | DAZN DE</span>
                <div class="bottom clearfix">
                  <el-button type="text" style="color:#ADA8A8;font-family: 阿里妈妈数黑体;font-size: 18px;font-weight:bold;">正在直播</el-button>
                </div>
              </div>
            </el-card>
          </el-col> -->
        </el-row>
      </div>

      <!-- 新闻资讯 -->
      <div class="news" style="background-color: #181818; ">
        <table  width="100%">
          <tr>
            <td width="20px"><div class="left-red"></div></td>
            <td><div class="tips-title">
              <h3>新闻资讯</h3>
              <span>NEWS</span>
            </div></td>
            <td><div class="more" style="margin-right:40px;" @click="newsMore">更多...</div></td>
          </tr>
        </table>
        <ul>
          <li v-for="news in newsList" :key="news.id"><a href="#"  @click="goDetail(news.id)"><font class="newsTitle">{{ news.title }}</font><span>{{news.updateTime}}</span></a></li>
          <!-- <li><a href="#">中国综合格斗新星在国际比赛中获得冠军 <span>24/04/06</span></a></li>
          <li><a href="#">中国综合格斗选手突破重围，成功晋级世界锦 <span>24/04/06</span></a></li>
          <li><a href="#">精彩对决！中国综合格斗大赛引爆全场 <span>24/04/06</span></a></li>
          <li><a href="#">中国综合格斗界迎来新一代天才，创造历史性 <span>24/04/06</span></a></li>
          <li><a href="#">深入探讨：中国综合格斗的崛起与发展 <span>24/04/06</span></a></li> -->
        </ul>
      </div>

      <!-- 次要直播 -->
      <div class="s-live" style="background-color: #181818; ">
        <table  width="100%">
          <tr>
            <td width="20px"><div class="left-red"></div></td>
            <td><div class="tips-title">
              <h3>直播</h3>
              <span>NEWS</span>
            </div></td>
            <td><div class="more" style="margin-right:10px;" @click="liveMore">更多...</div></td>
          </tr>
        </table>
        <el-image style="width:450px;height: 180px;margin-top:5px;margin-left:17px;" :src=liveImgSrc :fit=liveImgFit></el-image>
      </div>

      <div class="geduan">&nbsp;</div>

      <!-- 精彩视频 -->
      <div class="n-video" style="background-color: #181818; ">
        <div class="n-video-title" >
          <h3 style="color:red;">GREAT VIDEO</h3>
          <h3 @click="videoMore">精彩视频</h3>
        </div>
        <el-row :gutter="12">
            <el-col :span="6" v-for="item in videoList" :key="item.id">
              <el-card shadow="hover" style="background-color: #181818; border-color:#181818;" >
                <img :src="item.cover" class="live-1-image" style="width:290px; height:180px;" @click="goDetail(item.id)">
              </el-card>
            </el-col>
            <!-- <el-col :span="6">
              <el-card shadow="hover" style="background-color: #181818; border-color:#181818;">
                <img src="@/assets/n-video2.png" class="live-1-image" style="width:310px; height:180px;">
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover" style="background-color: #181818; border-color:#181818;">
                <img src="@/assets/n-video3.png" class="live-1-image" style="width:310px; height:180px;">
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover" style="background-color: #181818; border-color:#181818;">
                <img src="@/assets/n-video4.png" class="live-1-image" style="width:310px; height:180px;">
              </el-card>
            </el-col> -->
          </el-row>
      </div>

      <!-- 赛事动态 -->
      <div class="contest">
        <table  width="100%">
        <tr>
          <td width="20px"><div class="left-red"></div></td>
          <td><div class="tips-title">
            <h3>赛事动态</h3>
            <span>EVENT DYNAMICS</span>
          </div></td>
          <td><div class="more" style="margin-right:30px; " @click="contestMore">更多...</div></td>
        </tr>
      </table>
        <table width="98%" style="border-collapse:separate; border-spacing:0px 10px;">
          <tr v-for="item in contestList" :key="item.id" >
            <td class="contest-date" width="10%">{{ item.playTime }}</td>
            <td class="contest-p1-img" width="17%"><img class="contest-p1-img" :src="item.player1Avatar" @click="goPlayerInfo(item.player1)"/></td>
            <td class="contest-names" width="15%" @click="goContest(item)">
              <h3 style="color:white ;">{{item.player1Name}} VS {{item.player2Name}}</h3>
              <h3 style="color:red;">{{item.place}}</h3>
            </td>
            <td class="contest-p2-img" width="17%"><img class="contest-p1-img" :src="item.player2Avatar" @click="goPlayerInfo(item.player2)"/></td>
            <td class="contest-status" width="40%" @click="goContest(item)">
              <span :style="{color: item.liveStatus==2 ? 'red':'white' }">{{ item.liveStatus==3 ? '已结束' : (item.liveStatus==1 ? '未开始' : '进行中') }}</span>
            </td>
            <td class="contest-data" ><el-button v-if="item.liveStatus==3" type="danger" icon="el-icon-s-data" style="background-color: #FA0202; " @click="analysis(item)">数据分析</el-button></td>
          </tr>
        </table>
      </div>

    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-center">
        <table width="100%" height="100%">
          <tr>
            <td rowspan="2" width="13%" style="text-align:center">
              <img src="@/assets/logo.png" width="80%">
              <h3 style="text-align:center;color:white;">微信公众号</h3>
            </td>
            <td rowspan="2" width="13%" style="text-align:center">
              <img src="@/assets/logo.png" width="80%">
              <h3 style="text-align:center;color:white;">抖音二维码</h3>
            </td>
            <td width="13%" height="50%" style="text-align:center;vertical-align: text-top;">
              <h3 style="text-align:center;color:white;">地方协会</h3>
              <span style="color:red;">LOCAL ASSOCIATIONS</span>
              </td>
            <td>
              <ul class="hui" style="color:white;">
                <li>武汉</li>
                <li>延边</li>
                <li>成都</li>
                <li>上海</li>
                <li>西安</li>
                <li>杭州</li>
                <li>深圳</li>
                <li>乌鲁木齐</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td width="13%" style="text-align:center; vertical-align: text-top;">
              <h3 style="text-align:center;color:white;">友情链接</h3>
              <span style="color:red;">FRIENDSHIP LINK</span>
              </td>
            <td>
              <ul class="hui" style="color:darkgray;">
                <li>MMA官网</li>
                <li>中国格斗协会</li>
                <li>中国武术协会</li>
                <li>武林风</li>
                <li>UFC</li>
                <li>昆仑决</li>
                <li>运动员联盟</li>
                <li>中国体育</li>
              </ul>
            </td>
          </tr>
        </table>
         <div style="text-align:center;color:darkgray;  background-color: #181818;">
              &copy;2024星云恒宇（北京）国际体育科技发展有限公司 版权所有 <span style="color:white;">京ICP证9999999号</span>
            </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Index',
  props: {
    msg: String
  },
  data() {
    return {
      navList:[],
      imgList: [
        // {id:2,idView:require("@/assets/course3.png")}
      ],
      noticeList:[],
      mLiveList:[],
      newsList:[],
      videoList:[],
      liveImgSrc:require("@/assets/live-m-1.png"),
      liveImgFit:"cover",
      contestList: [],
      activeLink: null,
    };
  },
  created(){
    this.activeLink = this.$route.query.link  ;
    // 初始化页面数据
    this.init();
  },
  methods: {
    init(){
    // 加载导航
    axios.get('/cms/front/channelList?siteId=2')
        .then(response => {
          this.navList = response.data.data;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    // 加载巨幅广告
    axios.get('/cms/front/firstPageModule?contentType=huge')
        .then(response => {
          this.imgList = response.data;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    // 加载通知
    axios.get('/cms/front/firstPageModule?contentType=notice')
        .then(response => {
          this.noticeList = response.data.data;
          console.log(this.noticeList);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    // 加载直播列表
      axios.get('/cms/front/firstPageModule?contentType=live')
        .then(response => {
          this.mLiveList = response.data.data.slice(0, 3);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    // 加载新闻列表
    axios.get('/cms/front/firstPageModule?contentType=news')
        .then(response => {
          this.newsList = response.data.data.slice(0, 5);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    // 加载精彩视频
    axios.get('/cms/front/firstPageModule?contentType=video')
        .then(response => {
          this.videoList = response.data.data.slice(0, 4);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });  
    // 加载赛事动态
    axios.get('/cms/front/firstPageContest')
        .then(response => {
          this.contestList = response.data.data.slice(0, 4);
          // console.log(response.data.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });  
    },
    go(link){
      if(link == null || link == ''){
        this.$router.push("/");
      }else{
        this.$router.push({
          path: link,
          query: {'link': link}
        });
      }
    },
    goDetail(id){
      this.$router.push({
        path: '/detail',
        query: {'contentId': id}
      });
    },
    noticeMore(){
      this.$router.push({
        path: '/notices',
      });
    },
    newsMore(){
      this.$router.push({
        path: '/news',
      });
    },
    liveMore(){
      this.$router.push({
        path: '/lives',
      });
    },
    videoMore(){
      this.$router.push({
        path: '/videos',
      });
    },
    contestMore(){
      this.$router.push({
        path: '/contest',
      });
    },
    goContest(item){
      if(item.liveStatus == 1){
        alert("比赛尚未开始！")
      }else{
        this.$router.push({
          path: '/contestDetail',
          query: {'contestId': item.id}
        });
      }
    },
    analysis(item){
      this.$router.push({
        path: '/contestAnalysis',
        query: {'contestId': item.id}
      });
    },
    goPlayerInfo(playerId){
      this.$router.push({
        path: '/playerInfo',
        query: {'playerId': playerId},
      });
    }
  }
}
</script>

<style scoped>
.main{
  background-color: black;
  border:1px solid black;
  height: 2650px;
  font-size: 12px;
}
.main-center{
    width: 76%;
  margin: 0px auto;
}
.top-menu li:hover{
  cursor: pointer;
  color: aliceblue;
}

.liActive{
  font-weight: bold;
  color: honeydew;
}
.top{
  border:1px solid black;
  height: 80px;;
  background-color: black;
}
.logo{
  width: 128px;
  height: 68px;
  float: left;
  position: relative;
  top: 8px;
}
.top-menu {
  width: 600px;
  height: 40px;
  font-size: 12px;
  background-color: red;
  border-radius: 6px;
  color: black;
  float: left;
  margin-left: 30px;;
  margin-top: 30px;
}
.top-menu li{
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  /* border:1px solid black; */
}
a{
  text-decoration: none;
}
.top-menu-right img{
  height: 46px;
  float: right;
  position: relative;
  top:-3px;
  left: 40px;;
}
.top-menu ul {
  list-style-type: none;
  padding: 0;
}
.top-menu li {
  display: inline-block;
  margin: 0 10px;
}

.user{
  float: right;
  margin-top:30px;
  margin-right: 20px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
.carousel{
  width: 100%;
  height: 808px;;
}
.left-red{
  background: red;
  width: 10px;
  height: 30px;
  float: left;
}
.tips-title{
  color:white;
}
.tips-title h3{
  padding: 0px;
  margin: 0px;
}
.more{
  float: right;
  font-size: 1.2em;
  color:#D43030;
  cursor: pointer;
}
.more:hover{
  color: white;
}
.notice{
  margin:5px;
  padding:10px;
  background-color: #181818;
  border-radius: 3px;
  /* border:1px solid red; */
}
.notice-new{
  position: absolute;
  top: -10px;
  left: 440px;
}
.notice h3{
  padding:0;
  margin:0;
}
.notice el-card{
  background-color:#0F0F1A;}
.notice-top{
  height: 40px;;
}
.notice-title{
  padding:0;
  margin:0;
  font-size: 1.5em;
  cursor: pointer;
}
.notice-date{
  padding:0;
  margin:0;
}
.live-1-image{
  width: 280px;
}
.m-live {
  width: 98%;
  margin:0px auto;
  margin-top:20px;
  background-color: #181818;
  padding:10px;
  border-radius: 3px;
}
.m-live .el-card{
  height: 200px;
  padding:0px;
  margin:0px;
  cursor: pointer;
}
.m-live-font{
  font-family: 阿里妈妈数黑体;
  font-size: 14px;
  font-weight: bold;
  width: 38%;
  position: relative;
  top:-140px;
  left: 300px;
}
.m-live-font span{
  display: block;
  /* border: 1px solid red; */
  width: 80px;
}

.n-video{
  width: 99%;
  height: 280px;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3px;
  border:1px solid #181818;
  border-radius: 3px;
  cursor: pointer;
}

.n-video-title{
  padding: 0;
  margin: 0;
  margin-top: 15px;
  width: 100%;
  clear: both;
  text-align: center;
  color:white;
}
.n-video-title h3{
  padding: 0;
  margin: 0;
}
:deep(.n-video .el-card .el-card__body) {
  padding: 5px;
  margin-top: 20px;;
}

.news{
  padding:10px;
  width: 57%;
  height: 230px;
  border:1px solid black;
  float: left;
  margin-top: 20px;
  margin-left: 5px;
  border-radius: 3px;
}
.news li{
  height: 30px;
  line-height: 30px;
  width: 95%;
  border-bottom:dotted white ;
  border-bottom-width: thin;
  vertical-align: middle;
  color:white;
}
.news li a{
  color:white;
}
.news li a span{
  display: block;
  float: right;
  color: #a6a6a6;
}

.newsTitle{
  display: block;
  float: left;
  max-width: 450px;
  overflow:hidden;
  text-overflow:ellipsis;
  /*禁止换行显示*/
  white-space:nowrap;
  /* border:1px solid red; */
}

.s-live{
  padding:10px;
  width: 38%;
  border:1px solid black;
  float: right;
  height: 230px;
  margin-top: 20px;
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.geduan{
  float: left;
  clear: both;
  height: 20px;
}
.contest{
  width: 98%;
  height: 500px;
  float: left;
  border:1px solid #181818;
  background-color: #181818;
  clear: both;
  padding: 10px;
  border-radius: 3px;
  margin-left: 3px;
}
.contest table{
  border-collapse: collapse;
  border: none;
  padding:5px;
  cursor: pointer;
}
.contest tr{
  margin: 25px;
  border-radius: 3px;
}
.contest .contest-date{
  width: 140px;
  font-size:1.3em;
  text-align: center;
  color:white ;
}
.contest .contest-p1-img,.contest-p2-img{
  width: 90px;
  height: 90px;
  margin-left: 30px;
  vertical-align: middle;
  background-color: #701906;
}

.contest .contest-names,.contest-status,.contest-data{
  background-color: #701906;
  text-align: center;
}
/* .contest-status{
  border:1px solid black;
} */

.footer{
  width: 100%;
  height: 320px;
  margin-top:20px;
  /* border:1px solid black; */
  float: left;
  clear: both;
  background-color: #181818;
}

.footer-center{
  width: 80%;
  height: 300px;
  margin: 0px auto;
  /* border:1px solid black; */
}
.footer-center ul{
  padding:0px;
  margin: 0px;
}
.footer-center li{
  float: left;
  height: 30px;
  width: 260px;
  text-align: center;
  list-style: none;
}
</style>
