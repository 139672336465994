import VueRouter from 'vue-router'
import Vue from 'vue'
import Index from '../views/index.vue'
import News from '../views/news.vue'
import Contest from '../views/contest.vue'
import Detail from '../views/detail.vue'
import Notices from '../views/notices.vue'
import Lives from '../views/lives.vue'
import Videos from '../views/videos.vue'
import ContestDetail from '../views/contestDetail.vue'
import FightCourse from '../views/fightCourse.vue'
import Authentication from '../views/authentication.vue'
import ContestAnalysis from '../views/contestAnalysis.vue'
import PlayerInfo from '../views/playerInfo.vue'


Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            title: '新闻资讯'
        }
    },
    {
        path: '/contest',
        name: 'contest',
        component: Contest,
        meta: {
            title: '赛事动态'
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail,
        meta: {
            title: '详情'
        }
    },
    {
        path: '/notices',
        name: 'notices',
        component: Notices,
        meta: {
            title: '详情'
        }
    },
    {
        path: '/lives',
        name: 'lives',
        component: Lives,
        meta: {
            title: '直播列表'
        }
    },
    {
        path: '/videos',
        name: 'videos',
        component: Videos,
        meta: {
            title: '精彩视频'
        }
    },
    {
        path: '/contestDetail',
        name: 'contestDetail',
        component: ContestDetail,
        meta: {
            title: '比赛观看'
        }
    },
    {
        path: '/fightCourse',
        name: 'fightCourse',
        component: FightCourse,
        meta: {
            title: '格斗讲堂'
        }
    },
    {
        path: '/authentication',
        name: 'authentication',
        component: Authentication,
        meta: {
            title: '认证查询'
        }
    },
    {
        path: '/contestAnalysis',
        name: 'contestAnalysis',
        component: ContestAnalysis,
        meta: {
            title: '数据分析'
        }
    },
    {
        path: '/playerInfo',
        name: 'playerInfo',
        component: PlayerInfo,
        meta: {
            title: '选手资料'
        }
    }
]

const router = new VueRouter({
    routes
})

export default router
